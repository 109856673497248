var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"v-container-fluid",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"container-profile"},[_c('v-container',{staticStyle:{"width":"100%"},attrs:{"fluid":""}},[(_vm.isResponsive)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('p',{staticClass:"text-title mon-bold"},[_vm._v(_vm._s(_vm.texts.order.textTitle))])])]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"display-flex align-items-center"},[_c('v-text-field',{staticClass:"global-inputs mon-regular mr-2",attrs:{"type":"text","outlined":"","color":"#000000","dense":"","placeholder":_vm.texts.order.filters.textSearch,"persistent-placeholder":"","clearable":"","append-icon":"mdi-magnify","maxLength":"100"},model:{value:(_vm.sSearch),callback:function ($$v) {_vm.sSearch=$$v},expression:"sSearch"}}),_c('order-filter-layout',{attrs:{"tab":_vm.tab,"texts":_vm.texts}})],1),_c('div',{staticClass:"display-flex align-items-center mt-4"},[_c('v-menu',{ref:"bMenuFinal",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"global-inputs inputs-date mon-regular mr-2",attrs:{"outlined":"","color":"#000000","dense":"","readonly":"","clearable":"","hide-details":"","append-icon":"mdi-calendar","placeholder":_vm.texts.order.filters.startDate,"label":_vm.texts.order.filters.startDate,"persistent-hint":""},on:{"click:clear":function($event){_vm.startDate = null; _vm.endDate = null;}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},on))]}}],null,false,80964229),model:{value:(_vm.bMenuFinal),callback:function ($$v) {_vm.bMenuFinal=$$v},expression:"bMenuFinal"}},[_c('v-date-picker',{attrs:{"color":"#C41F79","no-title":""},on:{"input":function($event){_vm.bMenuFinal = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('div',{staticClass:"display-flex align-items-center mt-4"},[_c('v-menu',{ref:"bMenuInitial",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"global-inputs inputs-date mon-regular mr-2",attrs:{"outlined":"","color":"#000000","dense":"","readonly":"","clearable":"","hide-details":"","append-icon":"mdi-calendar","placeholder":_vm.texts.order.filters.endDate,"label":_vm.texts.order.filters.endDate,"persistent-hint":""},on:{"click:clear":function($event){_vm.endDate = null;}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},on))]}}],null,false,2544253667),model:{value:(_vm.bMenuInitial),callback:function ($$v) {_vm.bMenuInitial=$$v},expression:"bMenuInitial"}},[_c('v-date-picker',{attrs:{"min":_vm.startDate,"color":"#C41F79","no-title":""},on:{"input":function($event){_vm.bMenuInitial = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)])],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',[_c('p',{staticClass:"text-title mon-bold"},[_vm._v(_vm._s(_vm.texts.order.textTitle))])])]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"display-flex align-items-center"},[_c('v-text-field',{staticClass:"global-inputs mon-regular mr-2",attrs:{"type":"text","outlined":"","color":"#000000","dense":"","placeholder":_vm.texts.order.filters.textSearch,"persistent-placeholder":"","clearable":"","append-icon":"mdi-magnify","maxLength":"100"},model:{value:(_vm.sSearch),callback:function ($$v) {_vm.sSearch=$$v},expression:"sSearch"}}),_c('v-menu',{ref:"bMenuFinal",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"global-inputs inputs-date mon-regular mr-2",attrs:{"outlined":"","color":"#000000","dense":"","readonly":"","clearable":"","hide-details":"","append-icon":"mdi-calendar","placeholder":_vm.texts.order.filters.startDate,"label":_vm.texts.order.filters.startDate,"persistent-hint":""},on:{"click:clear":function($event){_vm.startDate = null; _vm.endDate = null;}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},on))]}}]),model:{value:(_vm.bMenuFinal),callback:function ($$v) {_vm.bMenuFinal=$$v},expression:"bMenuFinal"}},[_c('v-date-picker',{attrs:{"color":"#C41F79","no-title":""},on:{"input":function($event){_vm.bMenuFinal = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-menu',{ref:"bMenuInitial",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"global-inputs inputs-date mon-regular mr-2",attrs:{"outlined":"","color":"#000000","dense":"","readonly":"","clearable":"","hide-details":"","append-icon":"mdi-calendar","placeholder":_vm.texts.order.filters.endDate,"label":_vm.texts.order.filters.endDate,"persistent-hint":""},on:{"click:clear":function($event){_vm.endDate = null;}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},on))]}}]),model:{value:(_vm.bMenuInitial),callback:function ($$v) {_vm.bMenuInitial=$$v},expression:"bMenuInitial"}},[_c('v-date-picker',{attrs:{"min":_vm.startDate,"color":"#C41F79","no-title":""},on:{"input":function($event){_vm.bMenuInitial = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('order-filter-layout',{attrs:{"tab":_vm.tab,"texts":_vm.texts}})],1)])],1)],1),[_c('v-tabs',{attrs:{"show-arrows":"","background-color":"transparent"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{staticClass:"separator-line-tab"}),_c('v-tab',{staticClass:"tabs-profile mon-regular",on:{"click":_vm.tab1}},[_vm._v(_vm._s(_vm.texts.order.textTab1))]),_c('v-tab',{staticClass:"tabs-profile mon-regular",on:{"click":_vm.tab2}},[_vm._v(_vm._s(_vm.texts.order.textTab2))])],1),_c('v-tabs-items',{attrs:{"touchless":true},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('order-tab-content-one-layout',{attrs:{"texts":_vm.texts}})],1),_c('v-tab-item',[_c('order-tab-content-two-layout',{attrs:{"texts":_vm.texts}})],1)],1)]],2)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }